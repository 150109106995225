import http from "../http-common";
class DataService {
  GetLanguageList() {
    return http.instanceCommon.get("/Common/GetLanguageList" );
  }
  GetFullMenu(){
    return http.instanceCommon.get("/Common/GetFullMenu" );
  }
  UnreadItems(params){
    return http.instanceCommon.get("/Notification/UnreadItems?" + params );
  }
  GetitemDetail(params){
    return http.instanceCommon.get("/Notification/GetItemDetail?" + params );
  }
  DeleteSampleData(){
    return http.instanceCommon.post("/Common/DeleteSampleData" );
  }
  async CheckPrivilege(params){
    return await http.instanceCommon.get("/Common/HasPermission?" + params);
  }
  GetUserWizard(){
    return http.instanceCommon.get("/Common/GetUserWizard");
  }
  async GetCompanyLogo(params){
    return await http.instanceCommon.get("/Common/GetCompanyLogo?" + params);
  }
}
export default new DataService();
